.large-tooltip-inner {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.37);
  box-sizing: border-box;
  border: 1px solid white;
  color: #333;

  max-width: calc(100vw - 2em);
  width: 400px;

  position: absolute;
}

.large-tooltip-hidden {
  visibility: hidden;
}

.large-tooltip-inner:hover {
  visibility: visible;
}
